<template>
  <v-container>
    <BackButton :handler="goToEvents"/>
    <v-container fluid>
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="bordered bg-white rounded-lg p-8">
          <div class="row">
            <div class="col-12 col-md-7 d-flex justify-center flex-column">
              <h3 class="text-2xl font-semibold" style="font-weight: 700 ; font-size: 20px; line-height: 20px">
                {{ event.name || "Create an Event" }} </h3>
              <p class="mt-5" style="font-weight: 500 ; font-size: 14px; ">
                {{ event.description && truncateWithEllipsis(event.description, 250) || "Event Description" }}
              </p>
              <p class="mt-2" style="font-weight: 500 ; font-size: 14px; ">
                <template v-if="event.start_date">
                  {{ event.start_date | dateformat }}
                </template>
                <template v-else>
                  Start Date
                </template>
                -
                <template v-if="event.end_date">
                  {{ event.end_date | dateformat }}
                </template>
                <template v-else>
                  End Date
                </template>
              </p>
            </div>
            <div class="col-12 col-md-5" style="">
              <div style="height: 250px; width: 100%">
                <image-uploader
                    :height="250"
                    :image_path="event.image_path"
                    defaultImage="workshop"
                    message-position="inside"
                    message-text="JPG and PNG images are allowed, Recommended dimensions 2180*1080 (2:1) Maximum Size 3MB"
                    @remove="
                  () => {
                    event.image = null;
                  }
                "
                    @upload="
                  (data) => {
                    event.image = data;
                  }
                "
                ></image-uploader>
              </div>
            </div>
          </div>
        </div>

        <v-row class="my-4" dense>
          <v-col cols="12">
            <div class="d-flex justify-space-between align-center">
              <p class="font-semibold text-base font-16 mb-0">General Information</p>

              <div class="d-flex gap-x-2 align-center">

                <v-switch
                    v-model="event.is_public"
                    :false-value="0"
                    :true-value="1"
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    label="Enable Online"

                ></v-switch>


                <v-switch
                    v-model="isEnableArabic"
                    :false-value="0"
                    :true-value="1"
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    label="Arabic"
                ></v-switch>
              </div>
            </div>
          </v-col>
        </v-row>

        <div class="bordered bg-white rounded-lg p-8 mb-7">
          <v-row>
            <v-col cols="12" md="6">
              <v-row dense>
                <v-col cols="12">
                  <label for="">
                    Event Name*
                  </label>
                  <v-text-field
                      v-model="event.name"
                      :rules="[(v) => !!v || 'Event Name is required']"
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col v-if="isEnableArabic" cols="12" md="12" sm="12">
                  <label for="">
                    Event Name (AR)*
                  </label>
                  <v-text-field
                      v-model="event.ara_name"
                      :rules="[(v) => !!v || 'Event Name (AR) is required']"
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      reverse
                  ></v-text-field>
                </v-col>
                <v-col :md="facility_has_seat_map?4:6" cols="12">
                  <label for="">
                    Service*
                  </label>
                  <v-select
                      v-model="event.venue_service_id"
                      :items="venueServices"
                      :rules="[(v) => !!v || 'Service is required']"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="venue_service_id"
                      outlined
                      required
                      @change="getFacilities(null)"
                  ></v-select>
                </v-col>
                <v-col :md="facility_has_seat_map?4:6" cols="12">
                  <label for="">
                    Facility*
                  </label>
                  <v-select
                      v-model="event.facility_id"
                      :items="[...facilities, { id: null, name: 'External' }]"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      outlined
                      @change="changeFacility(null)"
                  ></v-select>
                </v-col>
                <v-col v-if="facility_has_seat_map" :md="facility_has_seat_map?4:6" cols="12">
                  <label for="">
                    Seat Map*
                  </label>
                  <v-select
                      v-model="event.seat_map_id"
                      :items="allMapPlans"
                      :readonly="editMode"
                      :rules="[(v) => !!v || 'Seat Map is required']"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      item-text="map_name"
                      item-value="id"
                      outlined
                      required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <label for="">
                    Start date*
                  </label>
                  <date-picker-field
                      v-model="event.start_date"
                      :backFill="
                      checkBackfillPermission($modules.events.management.slug)
                    "
                      :dayName="true"
                      :hide-details="true"
                      :rules="[(v) => !!v || 'Start date is required']"
                      class-name="q-text-field shadow-0"
                      dense
                      label=""
                  ></date-picker-field>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <label for="">
                    End date*
                  </label>
                  <date-picker-field
                      v-model="event.end_date"
                      :backFill="
                      checkBackfillPermission($modules.events.management.slug)
                    "
                      :dayName="true"
                      :hide-details="true"
                      :rules="[(v) => !!v || 'End date is required']"
                      class-name="q-text-field shadow-0"
                      dense
                      label=""
                  ></date-picker-field>
                </v-col>
                <v-col cols="12" md="6">
                  <label for="">Attendee Privacy*</label>
                  <v-select
                      v-model="event.attendees_privacy"
                      :items="[
                      { slug: 'public', name: 'Public' },
                      { slug: 'private', name: 'Private' },
                    ]"
                      :rules="[(v) => !!v || 'Attendees Privacy is required']"
                      background-color="#fff"
                      class="q-autocomplete bg-transparent shadow-0"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="slug"
                      outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" v-if="configuration.enable_tag_restriction">
                  <label for="">Customer Tags</label>
                  <v-select
                      v-model="event.event_venue_customer_tags"
                      :items="venueCustomerTags"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      multiple
                      outlined
                      required
                      return-object
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <label for="">Description</label>
                <v-textarea
                    v-model="event.description"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    name="description"
                    outlined
                    rows="5"
                >
                  <v-tooltip slot="append" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span> To turn any word into a clickable hyperlink, use square brackets as illustrated here, and include the desired text within parentheses: [https://example.com] (text) </span>
                  </v-tooltip>
                </v-textarea>
              </div>
              <div v-if="isEnableArabic" class="mt-2">
                <label for="">
                  Description (AR)
                </label>
                <v-textarea
                    v-model="event.ara_description"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    name="description"
                    outlined
                    reverse
                    rows="5"
                    style="direction: rtl"
                >
                  <v-tooltip slot="append" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span
                    >
                        To turn any word into a clickable hyperlink, use square brackets as illustrated here, and include the desired text within parentheses: [https://example.com] (text)
                      </span
                      >
                  </v-tooltip>
                </v-textarea>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center gap-x-8">
                <v-switch
                    v-model="event.is_enable_booking_approval"
                    class="mx-0 my-0"
                    label="Enable Booking Approval"
                ></v-switch>
                <v-switch
                    v-model="event.participant_restriction"
                    class="mx-0 my-0"
                    label="Enable Participant Restriction"
                ></v-switch>
                <v-switch
                    v-if="checkReadPermission($modules.b2b.dashboard.slug)"
                    v-model="event.enable_partner"
                    :false-value="0"
                    :true-value="1"
                    class="mx-0 my-0"
                    label="Enable Partner Booking"

                ></v-switch>
                <v-autocomplete
                    v-if="event.enable_partner == 1"
                    :items="b2bTypes"
                    v-model="event.type_query"
                    item-value="id"
                    item-text="name"
                    outlined
                    multiple
                    background-color="#fff"
                    placeholder="Type"
                    class="q-autocomplete shadow-0"
                    style="max-width: 180px !important;"
                    hide-details="auto"
                    validate-on-blur
                    dense
                    :rules="[v => (v && v.length > 0) || 'Partner type is required']"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggleSelect()">
                      <v-list-item-action>
                        <v-icon :color="event.type_query.length > 0 ? 'teal darken-4' : ''">{{
                            getServiceIcon()
                          }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template
                      v-if="event.type_query.length === b2bTypes.length"
                      v-slot:selection="{ index }"
                  >
                    <span v-if="index === 0">All Types</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.name }}</span>
                    <span v-if="index === 1">, {{ item.name }}</span>
                    <span v-if="index === 2">, ...</span>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>
          </v-row>
        </div>
        <!--Event Schedules -->
        <div class="pb-10">
          <p class="font-semibold text-base font-16">Event Schedules</p>
          <div class="bordered rounded-lg p-4 bg-white">
            <v-card
                v-for="(eTiming, index) in event.event_schedules"
                :key="'et_' + index"
                class="my-8 bordered rounded-lg pb-8"
                flat
            >
              <div class="">
                <v-tooltip v-if="index > 0" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        absolute
                        class="shadow-0"
                        elevation="0"
                        fab
                        right
                        small
                        style="right:50px"
                        top
                        v-bind="attrs"
                        x-small
                        @click="deleteTiming(index)"
                        v-on="on"
                    >
                      <DeleteIcon/>
                    </v-btn
                    >
                  </template>
                  <span>Delete Timing</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        absolute
                        class="shadow-0"
                        fab
                        right
                        small
                        style="right:5px"
                        top
                        v-bind="attrs"
                        @click="duplicateTiming(index)"
                        v-on="on"
                    >
                      <CopyIcon/>
                    </v-btn
                    >
                  </template>
                  <span>Duplicate row</span>
                </v-tooltip>

              </div>
              <v-card-text class="pb-0 pt-10">
                <v-row>
                  <v-col cols="12" md="3">
                    <label for="">
                      Schedule Name
                    </label>
                    <v-text-field
                        v-model="eTiming.schedule_name"
                        :rules="[(v) => !!v || 'Schedule Name is required']"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <label for="">
                      Select Facility*
                    </label>
                    <v-select
                        v-model="eTiming.facility_id"
                        :items="[...facilities, { id: null, name: 'External' }]"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        item-text="name"
                        item-value="id"
                        outlined
                        @change="changeFacility(index)"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <div v-if="eTiming.facility_id == null" class="" style="position:relative;">
                      <v-btn
                          absolute
                          elevation="1"
                          fab
                          right
                          style="top:0"
                          top
                          x-small
                          @click="mapFullScreenDialogToggle(index)"
                      >
                        <v-icon>mdi-fullscreen</v-icon>
                      </v-btn>
                      <label for="">
                        Select External Location
                      </label>
                      <v-autocomplete
                          :key="'loc_' + index"
                          v-model="autocompleteLocationModel[index]"
                          :items="locationEntries[index]"
                          :loading="isLoading"
                          :rules="[(v) => !!v || 'location is required']"
                          :search-input.sync="locationSearchText[index]"
                          background-color="#fff"
                          class="q-autocomplete shadow-0"
                          dense
                          hide-details="auto"
                          hide-no-data
                          item-text="value"
                          item-value="value"
                          outlined
                          @change="changeLocation(index)"
                          @update:search-input="debouncedInputChange(index)"
                      ></v-autocomplete>
                      <GmapMap
                          :zoom="12"
                          map-type-id="terrain"
                          style="width: 100%; height: 200px; display:none;"
                          v-bind:center="{
                            lat: parseFloat(eTiming.latitude),
                            lng: parseFloat(eTiming.longitude),
                          }"
                          @click="updateCoordinates($event,index)"
                      >
                        <GmapMarker
                            ref="mapRef"
                            :clickable="true"
                            :draggable="true"
                            :position="{
                              lat: parseFloat(eTiming.latitude),
                              lng: parseFloat(eTiming.longitude),
                            }"
                            @dragend="updateCoordinates($event,index)"
                        />
                      </GmapMap>
                    </div>
                  </v-col>
                  <v-col cols="6" md="3">
                    <label for="">
                      Start Date*
                    </label>
                    <date-picker-field
                        v-model="eTiming.start_date"
                        :dayName="false"
                        :hide-details="true"
                        :maxDate="event.end_date"
                        :minDate="event.start_date"
                        :rules="[(v) => !!v || 'Start date is required']"
                        class="q-text-field shadow-0"
                        dense
                        @change="$forceUpdate()"
                        label=""
                    ></date-picker-field>
                  </v-col>
                  <v-col v-if="timings" cols="6" md="3">
                    <label for="">Start Time*</label>
                    <v-select
                        v-model="eTiming.start_time"
                        :items="timings.slice(0, timings.length - 1)"
                        :rules="[(v) => !!v || 'Start Time is required']"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        item-text="text"
                        item-value="value"
                        outlined
                    >
                      <template #prepend-inner>
                        <ClockIcon class="mt-1"/>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="6" md="3">
                    <label for="">
                      End Date*
                    </label>
                    <date-picker-field
                        v-model="eTiming.end_date"
                        :dayName="false"
                        :hide-details="true"
                        :maxDate="event.end_date"
                        :minDate="eTiming.start_date"
                        :rules="[(v) => !!v || 'End Date is required']"
                        class="q-text-field shadow-0"
                        dense
                        @change="$forceUpdate()"
                        label=""
                    ></date-picker-field>
                  </v-col>
                  <v-col v-if="timings" cols="6" md="3">
                    <label for="">End Time*</label>
                    <v-select
                        v-model="eTiming.end_time"
                        :items="timings.slice(1)"
                        :rules="[(v) => !!v || 'End Time is required']"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        item-text="text"
                        item-value="value"
                        outlined
                    >
                      <template #prepend-inner>
                        <ClockIcon class="mt-1"/>
                      </template></v-select>
                  </v-col>
                </v-row>

              </v-card-text>

            </v-card>
          </div>
          <v-btn
              class="mt-4"
              color="teal-color"
              dark
              elevation="0"
              @click="addTimeInterval"
          >
            + Add Time Interval
          </v-btn>
        </div>
        <!-- End Event Timings-->
        <!-- Lineups     -->
        <v-expansion-panels accordion class="mt-4 mb-8" flat>
          <v-expansion-panel class="bordered">
            <v-expansion-panel-header hide-actions>
              <template v-slot:default="{ open }">
                <div class="d-flex align-center gap-x-2">
                  <MinusIcon v-if="open"/>
                  <PlusIcon v-else/>
                  <p class="align-center font-semibold text-base font-16 p-0 m-0">Lineups </p>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-4">
              <div
                  v-for="(lineup, index) in event.lineups"
                  :key="'l_' + index"
                  class="mb-8 pa-4 bg-white bordered rounded-lg" style="position: relative;height: fit-content"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        absolute
                        color="red"
                        dark
                        elevation="0"
                        fab
                        right
                        top
                        v-bind="attrs"
                        x-small
                        @click="deleteLineUps(index)"
                        v-on="on"
                    >
                      <DeleteIcon/>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <div class="pb-8">
                  <v-row>
                    <v-col cols="3" xl="2"  >
                      <image-uploader
                          ref="lineup_image"
                          :height="260"
                          :image_path="lineup.image_path"
                          defaultImage="user"
                          text="Upload Picture"
                          @remove="
                  () => {
                    event.lineups[index].image = null;
                  }
                "
                          @upload="
                  (data) => {
                    event.lineups[index].image = data;
                  }
                "
                      ></image-uploader>
                    </v-col>
                    <v-col xl="6" cols="9"  >
                      <v-row dense>
                        <v-col cols="6">
                          <label for="">
                            Name or Person
                          </label>
                          <v-text-field
                              v-model="lineup.name"
                              background-color="#fff"
                              class="q-text-field shadow-0"
                              dense
                              hide-details="auto"
                              outlined
                              required

                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <label for="">
                            Type of Profession
                          </label>
                          <v-text-field
                              v-model="lineup.designation"
                              background-color="#fff"
                              class="q-text-field shadow-0"
                              dense
                              hide-details="auto"
                              outlined
                              required

                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                          <label for="">
                            Date
                          </label>
                          <date-picker-field
                              v-model="lineup.date"
                              :backFill="checkBackfillPermission($modules.events.management.slug)"
                              :hide-details="true"
                              :maxDate="event.end_date"
                              :minDate="event.start_date"
                              class="q-text-field shadow-0"
                              dense
                              label=""
                          ></date-picker-field>
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                          <label for="">
                            Start Time
                          </label>
                          <v-select
                              v-model="lineup.start_time"
                              :items="timings.slice(0, timings.length - 1)"
                              background-color="#fff"
                              class="q-autocomplete shadow-0"
                              dense
                              hide-details="auto"
                              item-text="text"
                              item-value="value"
                              outlined

                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                          <label for="">
                            End Time
                          </label>
                          <v-select
                              v-model="lineup.end_time"
                              :items="timings.slice(1)"
                              background-color="#fff"
                              class="q-autocomplete shadow-0"
                              dense
                              hide-details="auto"
                              item-text="text"
                              item-value="value"
                              outlined

                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col xl="4" cols="12"  >
                      <label for="">
                        Description
                      </label>
                      <v-textarea
                          v-model="lineup.description"
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          name="description"
                          outlined
                          rows="4"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-btn
                  class="mt-2"
                  color="teal-color"
                  dark
                  elevation="0"
                  @click="addLineUp"
              >
                + Add More Artists
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- End Lineups     -->
        <div class="bg-white bordered rounded-lg pa-4">
          <p class="align-center font-semibold text-base font-16 p-0">Tickets</p>
          <div
              v-for="(ticket, k) in event.tickets"
              :key="'t_' + k"
              class="mb-4 px-4 pt-4 pb-8 relative border-bottom"
          >
            <div class="d-flex justify-space-between align-center mb-3">
              <p class="rounded-circle bg-blue numbering-icon">
                {{ k + 1 }}
              </p>
              <v-tooltip bottom v-if="event.tickets.length > 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="red"
                      dark
                      elevation="0"
                      fab
                      v-bind="attrs"
                      x-small
                      @click="deleteTickets(k)"
                      v-on="on"
                  >
                    <DeleteIcon/>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </div>
            <v-row>
              <v-col cols="3" xl="2">
                <image-uploader
                    :defaultImage="'ground'"
                    :image_path="ticket.image"
                    @remove="
                      () => {
                        ticket.image = null;
                        ticket.data_url = null;
                      }
                    "
                    @result="
                      (dUrl) => {
                        ticket.data_url = dUrl;
                        $forceUpdate();
                      }
                    "
                    @upload="
                      (data) => {
                        ticket.file = data;
                      }
                    "
                    text="Ticket Image"
                ></image-uploader>
              </v-col>
              <v-col xl="6" cols="9">
                <v-row dense>
                  <v-col :cols="isEnableArabic ? 6 : 12">
                    <label for="">
                      Name*
                    </label>
                    <v-text-field
                        v-model="ticket.name"
                        :rules="[(v) => !!v || 'Ticket Name is required']"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col  cols="6" v-if="isEnableArabic">
                    <template v-if="isEnableArabic">
                      <label for="">
                        Name (Ar)
                      </label>
                      <v-text-field
                          v-model="ticket.ar_name"
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                      ></v-text-field>
                    </template>
                  </v-col>
                  <v-col md="6">
                    <label for="">
                      Schedule Access
                    </label>
                    <v-autocomplete
                        v-model="ticket.schedule_list"
                        :items="schedulesList"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="[v => (v && v.length > 0) || 'Schedule Access is required']"
                        item-value="index"
                        item-text="name"
                        outlined
                        multiple
                        background-color="#fff"
                        placeholder="Type"
                        class="q-autocomplete shadow-0"
                        hide-details="auto"
                        validate-on-blur
                        dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleSelectSchedule(k)">
                          <v-list-item-action>
                            <v-icon :color="ticket.schedule_list.length > 0 ? 'teal darken-4' : ''">{{
                                getScheduleIcon(k)
                              }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template
                          v-if="ticket.schedule_list.length === schedulesList.length"
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index === 0">All Types</span>
                      </template>
                      <template v-else v-slot:selection="{ item, index }">
                        <span v-if="index === 0">{{ item.name }}</span>
                        <span v-if="index === 1">, {{ item.name }}</span>
                        <span v-if="index === 2">, ...</span>
                      </template>


                    </v-autocomplete>
                  </v-col>
                  <v-col md="6">
                    <label for="">
                      Access Type
                    </label>
                    <v-select
                        v-model="ticket.enable_multi_access"
                        :items="multi_acces_items"
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        item-text="name"
                        item-value="id"
                        outlined
                        required
                    ></v-select>
                  </v-col>
                  <v-col :lg="4">
                    <label for="">
                      Type*
                    </label>
                    <v-select
                        v-model="ticket.ticket_type"
                        :items="packageTypes"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="[(v) => !!v || 'Type is required']"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        item-text="name"
                        item-value="type"
                        outlined
                        required
                    ></v-select>
                  </v-col>
                  <v-col v-if="ticket.ticket_type == 'G'" md="4">
                    <label for="">
                      Participant count*
                    </label>
                    <v-text-field
                        v-model="ticket.participant_count"
                        :rules="[
                      (v) => !!v || 'Participant count',
                      (v) => !isNaN(v) || 'Participant count must be Number',
                    ]"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col :md="4">
                    <label for="">
                      Quantity*
                    </label>
                    <v-text-field
                        v-model="ticket.quantity"
                        :rules="[
                      (v) => !!v || 'Quantity is required',
                      (v) => !isNaN(v) || 'Quantity must be Number',
                    ]"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col md="4">
                    <label for="">
                      Tax*
                    </label>
                    <v-select
                        v-model="ticket.tax_type_id"
                        :items="taxTypes"
                        :rules="[(v) => !!v || 'Tax is required']"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        item-text="text"
                        item-value="value"
                        outlined
                        @change="taxChange(ticket)"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <label for="">
                      Price (Pre Tax)*
                    </label>
                    <v-text-field
                        v-model="ticket.pre_tax_price"
                        :prefix="currencyCode"
                        :rules="[
                      (v) => {
                        if (v && isNaN(v)) return 'Price must be Number';
                        return true;
                      },
                    ]"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        required
                        @change="calculateTaxVariation($event, ticket, 'pre')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <label for="">
                      Price (Post Tax)*
                    </label>
                    <v-text-field
                        v-model="ticket.total_price"
                        :prefix="currencyCode"
                        :rules="[
                      (v) => {
                        if (v && isNaN(v)) return 'Price must be Number';
                        return true;
                      },
                    ]"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        required
                        @change="calculateTaxVariation($event, ticket, 'post')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isEnableDctErp">
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                        v-model="projectNumber"
                        :readonly="projectNumber?true:false"
                        :rules="[(v) => !!v || 'Project number is required']"
                        background-color="#fff"
                        hide-details="auto"
                        label="Project Number *"
                        outlined
                        placeholder="Project Number"
                        required
                    ></v-text-field>
                    <!-- <v-text-field
                      outlined
                      background-color="#fff"
                      v-model="ticket.project_no"
                      hide-details="auto"
                      label="Project Number *"
                      placeholder="Project Number"
                      required
                      :rules="[(v) => !!v || 'Project number is required']"
                    ></v-text-field> -->
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field
                        v-model="transactionType"
                        :readonly="transactionType?true:false"
                        :rules="[(v) => !!v || 'Transaction Type is required']"
                        background-color="#fff"
                        hide-details="auto"
                        label="Transaction Type*"
                        outlined
                        placeholder="Transaction Type"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-select
                        v-model="ticket.task_name"
                        :items="taskNames"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="[(v) => !!v || 'Task name is required']"
                        background-color="#fff"
                        hide-details="auto"
                        hint="Required Task Name"
                        item-text="text"
                        item-value="value"
                        label="Task Name*"
                        outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <v-select
                        v-model="ticket.gl_code"
                        :items="glCodes"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="[(v) => !!v || 'GL code is required']"
                        background-color="#fff"
                        hide-details="auto"
                        hint="Required GL Code"
                        item-text="text"
                        item-value="value"
                        label="GL Code*"
                        outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col xl="4" cols="12" >
                <label for="">
                  Description
                </label>
                <v-textarea
                    v-model="ticket.description"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    name="description"
                    outlined
                    rows="4"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-btn
            color="teal-color mt-4"
            elevation="0"
            dark
            @click="addTickets"
        >
          + Add Ticket
        </v-btn>
        <v-expansion-panels accordion class="mt-4" flat>
          <v-expansion-panel class="bordered">
            <v-expansion-panel-header hide-actions>
              <template v-slot:default="{ open }">
                <div class="d-flex align-center gap-x-2">
                  <MinusIcon v-if="open"/>
                  <PlusIcon v-else/>
                  <p class="align-center font-semibold text-base font-16 p-0 m-0">Documents</p>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-4">
              <div v-for="(document, k) in event.documents" :key="'d_' + k" style="position:relative;">
                <v-row>
                  <v-col cols="12" md="4" sm="4">
                    <label for="">
                      Name
                    </label>
                    <v-text-field
                        v-model="document.name"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <label for="">
                      Type
                    </label>
                    <v-select
                        v-model="document.document_type_id"
                        :items="documentTypes"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        item-text="name"
                        item-value="id"
                        outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <label for="">
                      {{ editMode && document.original_file_name ? document.document_type_id : "Document" }}
                    </label>
                    <v-file-input
                        v-model="document.file"
                        background-color="#fff"
                        class="q-text-field shadow-0"
                        dense
                        hide-details="auto"
                        outlined
                        prepend-icon
                        prepend-inner-icon="mdi-paperclip"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col align-self="end" cols="12" sm="1">
                    <v-btn
                        :ripple="false"
                        class="no-hover-effect"
                        text
                        @click="deleteDocuments(k)"
                    >
                      <DeleteIcon/>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-btn
                  class="mt-4"
                  color="teal-color"
                  dark
                  elevation="0"
                  @click="addDocuments"
              >
               + Add Document
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="d-flex justify-end align-center mt-8">
          <v-btn
              class="ma-2 black--text text-capitalize" color="darken-1"
              style="background-color: rgba(17, 42, 70, 0.1)"
              text
              @click="updateEvent"
          >
            Save Draft
          </v-btn>
          <v-btn
              class="ma-2 white--text blue-color text-capitalize"
              color="darken-1"
              text
              @click="publishEvent"
          >
            Publish
          </v-btn>
        </div>

        <v-dialog v-model="locationDialoge" persistent scrollable width="40%">
          <v-card>
            <v-card-title class="headline">Select Location</v-card-title>
            <v-card-text class="pa-5">
              <v-row>
                Please select a location from the list. If you don't see correct
                location name in the list below try changing location on map by
                dragging the marker or clicking on the preffered location.
              </v-row>
              <v-row>
                <v-select
                    v-model="event.location"
                    :items="mapLocations"
                    item-text="formatted_address"
                    item-value="formatted_address"
                    outlined
                ></v-select>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="blue-color white--text" text @click="closeLocation"
              >Close
              </v-btn
              >
              <v-btn class="teal-color white--text" text @click="dragMapChange"
              >Done
              </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="mapFullScreenDialoge" scrollable width="80%">
          <v-card>
            <v-card-text class="pa-4">
              <v-row v-if="mapFullScreenDialoge && currentMapIndex != null" no-gutters>
                <v-col md="12">
                  <v-autocomplete
                      v-model="autocompleteLocationModel[currentMapIndex]"
                      :items="locationEntries[currentMapIndex]"
                      :loading="isLoading"
                      :search-input.sync="locationSearchTextPopup"
                      background-color="#fff"
                      hide-no-data
                      item-text="value"
                      item-value="value"
                      label="Location"
                      outlined
                      @change="changeLocation(currentMapIndex)"
                      @update:search-input="debouncedInputChangePopup(currentMapIndex)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <GmapMap
                  :zoom="12"
                  map-type-id="terrain"
                  style="width: 100%; height: 300px"
                  v-bind:center="{
                lat: parseFloat(event.event_schedules[currentMapIndex].latitude),
                lng: parseFloat(event.event_schedules[currentMapIndex].longitude),
              }"
                  @click="updateCoordinates($event,currentMapIndex)"
              >
                <GmapMarker
                    ref="mapRef"
                    :clickable="true"
                    :draggable="true"
                    :position="{
                  lat: parseFloat(event.event_schedules[currentMapIndex].latitude),
                  lng: parseFloat(event.event_schedules[currentMapIndex].longitude),
                }"
                    @dragend="updateCoordinates($event,currentMapIndex)"
                />
              </GmapMap>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-form>
    </v-container>
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
    <view-image-popup
        :data_url="currentImage.data_url"
        :image_path="currentImage.image_path"
        @resetImagePath="resetImagePath"
    ></view-image-popup>
  </v-container>
</template>
<script>
import {GetSuggestions, placeDetails} from "@/utils/placesUtils";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import ViewImagePopup from "@/components/Image/ViewImagePopup";
import moment from "moment";
import constants from "@/utils/constants";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import CopyIcon from "@/assets/images/misc/copy-bg-icon.svg";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";
import MinusIcon from "@/assets/images/misc/minus-icon.svg";
import PlusIcon from "@/assets/images/misc/plus.svg";
import ClockIcon from "@/assets/images/events/clock-input.svg";
import BackButton from "@/components/Common/BackButton.vue";
import DatePickerField from "@/components/Fields/DatePickerField.vue";

export default {
  components: {
    BackButton,
    PlusIcon, MinusIcon,
    DeleteIcon,
    CopyIcon,
    ImageUploader,
    ConfirmModel,
    ViewImagePopup,
    DatePickerField,
    ClockIcon
  },
  data() {
    return {
      schedulesList:[],
      addStageDialog: false,
      locationDialoge: false,
      mapFullScreenDialoge: false,
      mapLocations: [],
      locationHistory: {},
      valid: true,
      isEnableArabic: false,
      configuration:{
        enable_tag_restriction:false
      },
      event: {
        name: null,
        ar_name: null,
        type: "P",
        attendees_privacy: null,
        description: null,
        ar_description: null,
        venue_service_id: null,
        start_time: null,
        end_time: null,
        status_id: 1,
        event_venue_customer_tags: [],
        latitude: 24.46436049078158,
        longitude: 54.37532545660189,
        location: null,
        start_date: null,
        end_date: null,
        facility_id: null,
        event_schedules: [
          {
            schedule_name: null,
            facility_id: null,
            start_date: null,
            start_time: null,
            end_date: null,
            end_time: null,
            latitude: 24.46436049078158,
            longitude: 54.37532545660189,
            location: null,
          },
        ],
        lineups: [
          {
            name: null,
            designation: null,
            description: null,
            image: null,
            date: null,
          },
        ],
        documents: [
          {
            name: null,
            document_type_id: null,
            file: null,
          },
        ],
        tickets: [
          {
            name: null,
            enable_multi_access: 0,
            schedule_list:[],
            quantity: null,
            price: null,
            ticket_type: null,
            participant_count: null,
            description: null,
            image: null,
            image_url: null,
          },
        ],
        deletedTickets: [],
        deletedDocuments: [],
        deletedLineups: [],
        is_enable_booking_approval: false,
        is_public: 1,
        enable_partner: 0,
        type_query:[],
        participant_restriction: 0,
      },
      facilities: [],
      editMode: false,
      timings: [],
      lineups_timings: [],
      isLoading: false,
      autocompleteLocationModel: [],
      locationSearchText: [],
      locationSearchTextPopup: null,
      locationEntries: [[]],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      currentImage: {},
      packageTypes: [
        {type: "I", name: "Individual"},
        {type: "C", name: "Couple"},
        {type: "G", name: "Group"},
      ],
      facility_has_seat_map: false,
      seat_map_id: null,
      allMapPlans: [],
      taskNames: constants.TASK_NAMES,
      glCodes: constants.GL_CODES,
      projectNumber: null,
      transactionType: null,
      isEnableDctErp: false,
      currentMapIndex: 0
    };
  },
  watch: {
    "event.event_schedules":
        {
         handler: function(){
            this.syncSchedules();
        },
          deep:true,
    },
  },
  computed: {
    b2bTypes() {
      return this.$store.getters.getB2bTypes.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter((service) => service.name != "POS");
    },
    documentTypes() {
      return this.$store.getters.getDocumentTypes.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    enableAfhChanges() {
      return process.env.VUE_APP_ENABLE_AFH;
    },
    venueCustomerTags() {
      return this.$store.getters.getTags.data;
    },
    multi_acces_items(){
      return [
        { id:0, name:'Single'},{ id:1, name:'Multiple'},
      ];
    },
  },
  mounted() {
    if (this.$store.getters.getB2bTypes.status == false) {
      this.$store.dispatch("loadB2bTypes");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes").then(() => {
        if (typeof this.$route.params.data != "undefined") {
          this.event.id = parseInt(atob(this.$route.params.data));
          this.getEventDetails();
        }
      });
    } else {
      if (typeof this.$route.params.data != "undefined") {
        this.event.id = parseInt(atob(this.$route.params.data));
        this.getEventDetails();
      }
    }
    if (this.$store.getters.getDocumentTypes.status == false) {
      this.$store.dispatch("loadDocumentTypes");
    }
    if (this.$store.getters.venueInfo) {
      if (this.$store.getters.venueInfo.enable_dct_erp) {
        this.isEnableDctErp = true;
        if (this.$store.getters.venueInfo.dct_erp_configuration) {
          this.projectNumber = this.$store.getters.venueInfo.dct_erp_configuration.project_no;
          this.transactionType = this.$store.getters.venueInfo.dct_erp_configuration.transaction_type;
        }
      } else {
        this.isEnableDctErp = false;
      }
    }
    this.getConfiguration();
  },
  methods: {
    syncSchedules(){
      this.schedulesList = this.event.event_schedules.filter((ele) => ele.schedule_name &&  ele.schedule_name!="null").map((ele,index) => {
        return {
          id: ele.id,
          index: index,
          name: ele.schedule_name
        };
      });
    },
    getServiceIcon() {
      if (this.event.type_query.length == 0) return "mdi-checkbox-blank-outline";
      if (this.b2bTypes.length == this.event.type_query.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.event.type_query.length == this.b2bTypes.length) {
          this.event.type_query = [];
        } else {
          this.event.type_query = this.b2bTypes.map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getScheduleIcon(index) {
      if (!this.event.tickets[index].schedule_list || this.event.tickets[index].schedule_list.length == 0) return "mdi-checkbox-blank-outline";
      if (this.schedulesList.length == this.event.tickets[index].schedule_list.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
    toggleSelectSchedule(index) {
      this.$nextTick(() => {
        if (this.event.tickets[index].schedule_list && this.event.tickets[index].schedule_list.length == this.schedulesList.length) {
          this.event.tickets[index].schedule_list = [];
        } else {
          this.event.tickets[index].schedule_list = this.schedulesList.map((item) => item.index);
        }
      });
      this.$forceUpdate();
    },
    getConfiguration() {
      this.$http
          .get(`venues/events/configuration`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (data) {
                this.configuration = data.configuration;
              }
              this.deleted_codes = [];
              this.$forceUpdate();
              if (this.$refs.cform) {
                this.$refs.cform.resetValidation();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(()=> {
        this.syncSchedules();
      });
    },
    debouncedInputChange(index) {
      var _vue = this;
      let newVal = this.locationSearchText[index];
      if (newVal == null || newVal.length == 0 || this.autocompleteLocationModel[index] == newVal)
        return;
      this.isLoading = true;
      GetSuggestions(newVal, index)
          .then(function (res) {
            _vue.isLoading = false;
            _vue.locationEntries[index] = res;
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    debouncedInputChangePopup(index) {

      var _vue = this;
      let newVal = this.locationSearchTextPopup;
      if (newVal == null || newVal.length == 0 || this.autocompleteLocationModel[index] == newVal) {
        return;
      }

      this.isLoading = true;
      GetSuggestions(newVal, index)
          .then(function (res) {
            _vue.isLoading = false;
            _vue.locationEntries[index] = res;
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    showPopupImage(product) {
      this.currentImage = {
        image_path: product.image,
        data_url: product.data_url,
      };
    },
    resetImagePath() {
      this.currentImage = {};
    },
    changeLocation(index = null) {
      if (index == null) {
        return;
      }
      this.event.event_schedules[index].location = this.autocompleteLocationModel[index];
      let placeId = this.locationEntries[index].find(
          (val) => val.value == this.autocompleteLocationModel[index]
      ).id;
      placeDetails(placeId)
          .then((data) => {
            var lat = data[0].geometry.location.lat();
            var lng = data[0].geometry.location.lng();
            this.event.event_schedules[index].latitude = lat;
            this.event.event_schedules[index].longitude = lng;
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    dragMapChange() {
      this.locationEntries[this.currentMapIndex] = [{
        value: this.event.event_schedules[this.currentMapIndex].location,
        id: this.currentMapIndex
      }];
      this.autocompleteLocationModel[this.currentMapIndex] = this.event.event_schedules[this.currentMapIndex].location;
      this.locationSearchText[this.currentMapIndex] = this.event.event_schedules[this.currentMapIndex].location;
      this.locationDialoge = false;
    },
    addTimeInterval() {
      this.event.event_schedules.push({
        venue_service_id: null,
        facility_id: null,
        start_date: null,
        start_time: null,
        end_date: null,
        schedule_name:null,
        end_time: null,
        latitude: 24.46436049078158,
        longitude: 54.37532545660189,
        location: null,

      });
      this.$forceUpdate();
    },
    addLineUp() {
      this.event.lineups.push({
        name: "",
        type: "",
        description: "",
        date: null,
        start_time: null,
        end_time: null,
      });
    },
    addDocuments() {
      this.event.documents.push({
        name: "",
        document_type_id: "",
        file: null,
      });
    },
    addTickets() {
      this.event.tickets.push({
        name: "",
        enable_multi_access: 0,
        schedule_list:[],
        quantity: "",
        price: this.event.type == "Free" ? 0 : "",
        ticket_type: null,
        participant_count: null,
        description: null,
      });
    },
    eventTypeChange() {
      if (this.event.type == "Free") {
        this.event.tickets.map((item) => {
          item.price = 0;
        });
      }
    },
    updateEvent() {
      this.event.status_id = 11;
      this.addOrEditEvent();
    },
    publishEvent() {
      this.event.status_id = 1;
      this.addOrEditEvent();
    },
    duplicateTiming(index) {
      this.event.event_schedules.push({
        venue_service_id: this.event.event_schedules[index].venue_service_id,
        schedule_name: this.event.event_schedules[index].schedule_name+ ' Copy',
        facility_id: this.event.event_schedules[index].facility_id,
        start_date: this.event.event_schedules[index].start_date,
        start_time: this.event.event_schedules[index].start_time,
        end_date: this.event.event_schedules[index].end_date,
        end_time: this.event.event_schedules[index].end_time,
        latitude: this.event.event_schedules[index].latitude,
        longitude: this.event.event_schedules[index].longitude,
        location: this.event.event_schedules[index].location,

      });
      this.$forceUpdate();
    },
    deleteTiming(index) {
      if (!this.event.event_schedules[index].start_date && !this.event.event_schedules[index].end_date) {
        this.event.event_schedules.splice(index, 1);
        this.locationSearchText.splice(index, 1);
        this.currentMapIndex = 0;
        this.$forceUpdate();
        return true;
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this Time Interval?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "event_schedules",
        };
      }
    },
    deleteLineUps(index) {
      if (
          !this.event.lineups[index].name &&
          !this.event.lineups[index].designation
      ) {
        this.$refs[`lineup_image`][index].cancel();
        this.event.lineups.splice(index, 1);
        return true;
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this lineup?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "lineup",
        };
      }
    },
    deleteDocuments(index) {
      if (this.event.documents[index].event_document_id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this document?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "document",
        };
      } else {
        this.event.documents.splice(index, 1);
        if (this.event.documents.length == 0) {
          this.event.documents = [{}];
        }
      }
    },
    deleteTickets(index) {
      if (this.event.tickets[index].event_ticket_id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this ticket type?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "ticket",
        };
      } else {
        this.event.tickets.splice(index, 1);
        if (this.event.tickets.length == 0) {
          this.event.tickets = [{
            name: "",
            enable_multi_access: 0,
            schedule_list:[],
            quantity: "",
            price: this.event.type == "Free" ? 0 : "",
            ticket_type: null,
            participant_count: null,
            description: null,
          }];
        }
      }
    },
    confirmActions(data) {
      if (data.type == "lineup") {
        if (this.event.lineups[data.id].event_lineup_id != null)
          this.event.deletedLineups.push(
              this.event.lineups[data.id].event_lineup_id
          );
        if (this.event.lineups[data.id].image) {
          this.$refs[`lineup_image`][data.id].cancel();
        }
        setTimeout(() => {
          this.event.lineups.splice(data.id, 1);
          if (this.event.lineups.length == 0) {
            this.event.lineups = [{}];
          }
        });
      } else if (data.type == "document") {
        if (this.event.documents[data.id].event_document_id != null)
          this.event.deletedDocuments.push(
              this.event.documents[data.id].event_document_id
          );
        if (this.event.documents[data.id].file) {
          this.event.documents[data.id].file = null;
        }
        this.event.documents.splice(data.id, 1);
        if (this.event.documents.length == 0) {
          this.event.documents = [{}];
        }
      } else if (data.type == "ticket") {
        if (this.event.tickets[data.id].event_ticket_id != null)
          this.event.deletedTickets.push(
              this.event.tickets[data.id].event_ticket_id
          );
        this.event.tickets.splice(data.id, 1);
        if (this.event.tickets.length == 0) {
          this.event.tickets = [{}];
        }
      } else if (data.type == "event_schedules") {
        if (this.event.event_schedules[data.id].event_timing_id != null)
          this.event.deletedTimings.push(
              this.event.event_schedules[data.id].event_timing_id
          );
        setTimeout(() => {
          this.event.event_schedules.splice(data.id, 1);
          if (this.event.event_schedules.length == 0) {
            this.event.event_schedules = [{}];
          }
          this.$forceUpdate();
        });
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    /* eslint-disable */
    addOrEditEvent() {
      event.preventDefault();
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (this.venueServices.length == 0) {
        this.showError(
            "No Facilities found. Please add facilities to add event."
        );
        return;
      }
      this.showLoader("Saving");
      let formData = new FormData();
      formData.append("name", this.event.name);
      if (this.event.description)
        formData.append("description", this.sanitizeHTML(this.event.description));
      if (typeof this.event.image != "undefined" && typeof this.event.image != "string" && this.event.image != null) {
        formData.append("image", this.event.image);
      }
      formData.append("type", this.event.type);
      formData.append("enable_partner", this.event.enable_partner);

      this.event.type_query.forEach((element, index) => {
            formData.append(`type_query[${index}]`, element);
      });


      formData.append("attendees_privacy", this.event.attendees_privacy);
      formData.append("event_type", "Event");
      formData.append("venue_service_id", this.event.venue_service_id);
      formData.append("start_time", this.event.start_time);
      formData.append("end_time", this.event.end_time);
      formData.append("status_id", this.event.status_id);
      formData.append("start_date", this.event.start_date);
      formData.append("end_date", this.event.end_date);
      formData.append("is_enable_booking_approval", this.event.is_enable_booking_approval);

      let is_public = this.event.is_public ? 1 : 0;
      formData.append("is_public", is_public);

      let participant_restriction = this.event.participant_restriction ? 1 : 0;
      formData.append("participant_restriction", participant_restriction);

      if (this.facility_has_seat_map && this.event.seat_map_id) {
        formData.append("seat_map_id", this.event.seat_map_id);
      }
      /** Event Arabic Name and description */
      if (this.isEnableArabic) {
        formData.append("ara_name", this.event.ara_name);
        formData.append("ara_description", this.event.ara_description);
      }
      /** end Event Arabic name an description */
      // formData.append("status", this.event.status);
      if (this.event.deletedLineups.length) {
        this.event.deletedLineups.forEach((element, index) => {
          formData.append(`deleted_lineups[${index}]`, element);
        });
      }
      if (this.event.deletedTickets.length) {
        this.event.deletedTickets.forEach((element, index) => {
          formData.append(`deleted_tickets[${index}]`, element);
        });
      }
      if (this.event.deletedDocuments.length) {
        this.event.deletedDocuments.forEach((element, index) => {
          formData.append(`deleted_documents[${index}]`, element);
        });
      }

      // if (this.event.facility_id == null) {
      //   formData.append("latitude", this.event.latitude);
      //   formData.append("longitude", this.event.longitude);
      //   formData.append("location", this.event.location);
      //   if (this.event.heatmap != null)
      //     formData.append("heatmap", this.event.heatmap);
      // } else {
      //   formData.append("facility_id", this.event.facility_id);
      // }
      if (this.event.facility_id != null) {
        formData.append("facility_id", this.event.facility_id);
      }
      this.event.lineups.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (element[key] != null)
            formData.append(`lineups[${index}][${key}]`, element[key]);
        });
      });
      this.event.documents.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (element[key] != null)
            formData.append(`documents[${index}][${key}]`, element[key]);
        });
      });
      this.event.tickets.forEach((element, index) => {
        if (this.isEnableDctErp) {
          formData.append(`tickets[${index}]['project_no']`, this.projectNumber);
          formData.append(`tickets[${index}]['transaction_type']`, this.transactionType);
        }
        Object.keys(element).forEach((key) => {
          if (element[key] != null && key != "image") {
            formData.append(`tickets[${index}][${key}]`, element[key]);
          }
        });
        if (element.file) {
          formData.append(`tickets[${index}][image]`, element.file);
        }
      });
      this.event.event_schedules.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (element[key] != null)
            formData.append(`event_schedules[${index}][${key}]`, element[key]);
        });
      });

      this.event.event_venue_customer_tags.forEach((element, index) => {
        Object.keys(element).forEach((key) => {
          if (element[key] != null)
            formData.append(`venue_customer_tags[${index}][${key}]`, element[key]);
        });
      });


      if (typeof this.event.id != "undefined" && this.event.id != null) {
        formData.append("id", Number(this.event.id));
      }
      let header = {headers: {"Content-Type": "multipart/form-data; boundary=${form._boundary}",},}
      this.$http.post(`venues/events${this.event.id != null ? "/" + this.event.id : ""}`, formData, header).then((response) => {
        if (response.status == 200 && response.data.status) {
          this.showSuccess("Event saved successfully");
          this.hideLoader();
          this.goToEvents();
        }
      }).catch((error) => {
        this.hideLoader();
        this.errorChecker(error);
      });
    },
    updateCoordinates(e, index = null) {
      this.currentMapIndex = index;
      this.locationHistory.lat = this.event.event_schedules[index].latitude;
      this.locationHistory.lng = this.event.event_schedules[index].longitude;

      let lat = e.latLng.lat();
      let lng = e.latLng.lng();

      let geocoder = new google.maps.Geocoder();
      var locationss = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };
      let that = this;
      geocoder.geocode({location: locationss}, function (results, status) {
        if (status === "OK") {
          if (results.length > 0) {
            that.mapLocations = results;
            that.event.event_schedules[index].location = results[0].formatted_address;
            that.locationDialoge = true;
          }
        }
      });
      this.event.event_schedules[index].latitude = lat;
      this.event.event_schedules[index].longitude = lng;
    },
    mapFullScreenDialogToggle(index) {
      this.currentMapIndex = index;
      this.locationSearchTextPopup = this.locationSearchText[index];
      this.mapFullScreenDialoge = true
    },
    closeLocation() {
      this.locationDialoge = false;
      this.event.event_schedules[this.currentMapIndex].latitude = this.locationHistory.lat;
      this.event.event_schedules[this.currentMapIndex].longitude = this.locationHistory.lng;
      this.event.event_schedules[this.currentMapIndex].location = null;
      this.currentMapIndex = 0;
    },
    getFacilities(index = null) {
      let venueServiceId;
      venueServiceId = this.event.venue_service_id;
      this.$http
          .get(`venues/facilities/short?venue_service_id=${venueServiceId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.facilities = response.data.data;
              this.getMinBookingTimes(index);
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getEventDetails() {
      this.showLoader("Loading");
      this.$http
          .get(`venues/events/${this.event.id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.editMode = true;
              let eventData = response.data.data;
              this.event.status_id = 1;
              this.event = eventData;

              this.event.tickets.map(ele => {
                ele.schedule_list = ele.event_schedule.map(element => {
                  return element.index = this.event.event_schedules.findIndex(ele => ele.id === element.pivot.event_schedule_id);
                });
                return ele;
              })

              if(eventData.partner_types && eventData.partner_types.length){
                this.event.type_query = eventData.partner_types.map(partner => partner.id);
              }else{
                this.event.type_query = [];
              }
              this.event.event_venue_customer_tags = eventData.venue_customer_tags;
              if (eventData.ara_name && eventData.ara_name != "" && eventData.ara_name !== null) {
                this.isEnableArabic = 1;
              }
              this.event.event_schedules.map((schedule, index) => {
                if (!schedule.location) {
                  schedule.latitude = 24.46436049078158;
                  schedule.longitude = 54.37532545660189;
                  schedule.location = null;
                  this.locationEntries[index] = [];
                  this.autocompleteLocationModel[index] = null;
                  this.locationSearchText[index] = null;
                } else {
                  this.locationEntries[index] = [{value: schedule.location, id: index}];
                  this.autocompleteLocationModel[index] = schedule.location;
                  this.locationSearchText[index] = schedule.location;
                }
              })
              if (eventData.lineups.length == 0) {
                this.event.lineups = [{}];
              }
              if (eventData.documents.length == 0) {
                this.event.documents = [{}];
              }
              if (eventData.tickets.length == 0) {
                this.event.tickets = [{}];
              }

              if (eventData.event_schedules === null) {
                this.event.event_schedules = [
                  {
                    schedule_name: null,
                    facility_id: null,
                    start_date: null,
                    start_time: null,
                    end_date: null,
                    end_time: null,
                    latitude: 24.46436049078158,
                    longitude: 54.37532545660189,
                    location: null,
                  },
                ];
              } else {
                this.event.event_schedules = eventData.event_schedules;
              }
              this.event.deletedDocuments = [];
              this.event.deletedTickets = [];
              this.event.deletedLineups = [];
              this.hideLoader();
              this.getFacilities();
              if (eventData.seat_map_id) {
                this.event.seat_map_id = eventData.seat_map_id;
                this.facility_has_seat_map = true;
                this.getFacilitySeatMaps();
                this.setIndividualPackageType();
              }
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            console.log("error");
            console.log(error);
            this.errorChecker(error);
          });
    },
    goToEvents() {
      if (this.event.id != null) {
        this.$router.push({
          name: "EventsView",
          params: {data: btoa(this.event.id)},
        });
      } else {
        this.$router.push({name: "Events"});
      }
    },

    calculateTaxVariation(amount, ticket, type) {
      let taxTypeId = ticket.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId && this.taxTypes.length) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      if (priceData) {
        ticket.price = priceData.price;
        ticket.pre_tax_price = priceData.price.toFixed(2);
        ticket.total_price = priceData.total_price;
      }
      this.$forceUpdate();
    },

    taxChange(ticket) {
      if (ticket.price) {
        this.calculateTaxVariation(ticket.price, ticket, "pre");
      } else if (ticket.total_price) {
        this.calculateTaxVariation(ticket.total_price, ticket, "post");
      }
    },

    getMinBookingTimes(index = null) {
      let venueService;
      venueService = this.venueServices.find(
          (item) => item.venue_service_id == this.event.venue_service_id
      );
      const timeIncrement = venueService ? venueService.time_increment : 60;
      if (timeIncrement && index == null) {
        let currentTime = moment("00:00:00", "HH:mm:ss");
        this.timings = [];
        this.timings.push({
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        });
        let hour = 0;
        while (hour < 24) {
          currentTime = currentTime.add(timeIncrement, "minutes");
          let data = {
            value: currentTime.format("HH:mm:ss"),
            text: currentTime.format("hh:mm a"),
          };
          if (data.value == "00:00:00") {
            data.value = "23:59:59";
            this.timings.push(data);
            hour = 24;
          } else {
            this.timings.push(data);
            hour = currentTime.get("hours");
          }
        }
      }
    },
    enableArabic() {
      if (this.isEnableArabic) {
        this.isEnableArabic = false;
      } else {
        this.isEnableArabic = true;
      }
    },
    /** Seat Map functions */
    changeFacility(index) {
      let facility;
      if (index != null) {
        facility = this.facilities.find((f) => f.id === this.event.event_schedules[index].facility_id);
      } else {
        facility = this.facilities.find((f) => f.id === this.event.facility_id);
      }
      if (facility !== undefined) {
        if (facility.is_enabled_seat_map && index == null) {
          this.facility_has_seat_map = true;
          this.getFacilitySeatMaps();
          this.setIndividualPackageType();
        } else {
          // if(!facility.is_enabled_seat_map){
          //   this.facility_has_seat_map = false;
          //   this.allMapPlans = [];
          //   this.resetPackageType();
          // }
          this.checkFacilitySeatMap();
        }
      }
    },
    getFacilitySeatMaps() {
      if (!this.event.facility_id) {
        this.showError("Facility Id is required");
        return false;
      }
      if (!this.facility_has_seat_map) {
        return false;
      }
      this.showLoader("Loading");
      this.$http.get(`venues/seat-maps?facility_id=${this.event.facility_id}&status_id=1`).then((response) => {
        if (response.status == 200) {
          const data = response.data.data;
          if (data && data.length) {
            this.allMapPlans = data;
          } else {
            this.allMapPlans = [];
          }
          this.hideLoader();
        }
      })
          .catch((error) => {
            console.log(error);
            this.hideLoader();
          });
    },
    resetPackageType() {
      this.packageTypes = [
        {type: "I", name: "Individual"},
        {type: "C", name: "Couple"},
        {type: "G", name: "Group"},
      ];
    },
    setIndividualPackageType() {
      this.packageTypes = [{type: "I", name: "Individual"}];
    },
    checkFacilitySeatMap() {
      if (this.event && this.event.facility_id) {
        const eventFacility = this.facilities.find((f) => f.id === this.event.facility_id);
        if (eventFacility !== undefined) {
          if (eventFacility.is_enabled_seat_map) {
            let isSeatMapEnabled = true;
            this.event.event_schedules.forEach((es) => {
              if (es.facility_id) {
                let eventScheduleFacility = this.facilities.find((f) => f.id === es.facility_id);
                if (!eventScheduleFacility.is_enabled_seat_map) {
                  isSeatMapEnabled = false;
                }
              }
            });
            if (isSeatMapEnabled) {
              this.facility_has_seat_map = true;
              this.getFacilitySeatMaps();
              this.setIndividualPackageType();
            } else {
              this.facility_has_seat_map = false;
              this.allMapPlans = [];
              this.resetPackageType();
            }
          }
        }
      }

    },

    truncateWithEllipsis(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
      } else {
        return str;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.add_ground_containers {
  width: -webkit-fill-available;
}

.imageInput {
  padding-top: 0;
}

.add_btn {
  margin-top: -20px;
}

.numbering-icon {
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-weight: 500;
  color: white;
  font-size: 12px;
  text-align: center;
}
</style>
<style>
.document-file-input {
  .v-text-field__slot {
    max-width: 60%;
  }

  .v-file-input__text {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
